import { CATEGORY_TEASER } from "lacinetek-common/src/images"

import { useTranslation } from "lib/i18n/translation"
import { FETCH_YOUTUBE_CATEGORY_VIDEO_ID } from "lib/graphql/queries"
import { Query } from "components/common"

import { VideoPlayer } from "./guest"

const VideoContainer = ({ selection, videoId }) => (
	<VideoPlayer selection={selection} videoId={videoId} thumb={CATEGORY_TEASER} />
)

const Teaser = ({selection, tiny, loading}) => {

	const { c } = useTranslation("common")

	if (loading) {
		return null
	}

	let Container = "div"
	if (tiny) {
		Container = "section"
	}

	return <Query
		query={FETCH_YOUTUBE_CATEGORY_VIDEO_ID}
		options={{ variables: { id: selection.id } }}
		renderLoading={() => (<></>)}
	>
		{({ cms: { categories: { items: [{ videos: { items: [video] } }] } } }) => {
			if (!video) {
				return null
			}

			return (
				<Container id="selection-teaser">
					<h2 className="section-title icon-play">{c("teaser-of-the-month-strong")}</h2>
					{ tiny
						? <VideoContainer selection={selection} videoId={video.id} />
						: <div className="videoplayer-container">
								<VideoContainer selection={selection} videoId={video.id} />
							</div>
					}
				</Container>
			)
		}}
	</Query>
}

export default Teaser
