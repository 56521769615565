import { useEffect, useState } from "react"
import classNames from "classnames"
import moment from "moment"
import { useLazyQuery, useQuery } from "@apollo/react-hooks"
import image, { CATEGORY_SELECTION_PREVIOUS } from "lacinetek-common/src/images"

import { useTranslation } from "lib/i18n/translation"
import { useModal } from "lib/modal"
import { useCountry } from "lib/country"
import { SELECTION } from "lib/tools/categories"
import { FETCH_ALL_SELECTIONS, FETCH_YEAR_SELECTION } from "lib/graphql/queries"

import { Slider } from "components/common"
import Image from "components/common/image"
import { baseSelection } from "components/common/skeleton"
import { SelectionModal, SelectionModalFooter } from "pages/[lang]/selection"

const Others = ({ isSvod }) => {
	const rootCategory = process.env.CATEGORIES.find(c => c.key == SELECTION).id
	const { country } = useCountry()
	const [currentYear, setCurrentYear] = useState(new Date().getFullYear())
	const [current, setCurrent] = useState({
		id: 9999999,
		children: {
			items: [baseSelection, baseSelection, baseSelection, baseSelection]
		},
		year: new Date().getFullYear()
	})
	const [allSelections, setAllSelections] = useState([])
	const { show } = useModal()
	const { c } = useTranslation("common")
	const { data, loading } = useQuery(FETCH_YEAR_SELECTION, {
		variables: {
			countryQuery: `parent: ${rootCategory} AND linkRewrite.value: selection-${country ? country.isoCode : baseCountry.isoCode}`
		}
	})
	const [getSelections, { data: selectionData, loading: selectionLoading }] = useLazyQuery(FETCH_ALL_SELECTIONS)

	const showSelection = (selection, selections, year) => {

		if (selection == null) {
			return
		}

		const currentIndex = selections.map(s => s.id).indexOf(selection.id)
		const previousSelection = (currentIndex < (selections.length - 1)) ? selections[currentIndex + 1] : null
		const nextSelection = currentIndex ? selections[currentIndex - 1] : null

		show({
			id: `month-selection-overlay-${selection.id}`,
			children: <SelectionModal slug={selection.linkRewrite} />,
			className: "is-archive slide right",
			footerClassName: "fixed",
			footer: (
				<SelectionModalFooter
					previousSelection={previousSelection}
					nextSelection={nextSelection}
					year={year}
					show={s => showSelection(s, selections, year)}
				/>
			)
		})
	}

	const sortYears = (a, b) => ((a >= b) ? -1 : 1)

	const generateYears = (data) => {
		let maxYear = new Date().getFullYear()
		const currentMonth = new Date().getMonth()

		if (!currentMonth > 0) {
			maxYear--
		}

		const minYear = parseInt(data.cms.categories.items[0].children.items[0].name.split("-")[0])
		const years = []
		let yeartIterator = minYear
		for (let i = 0; i <= (maxYear - minYear); i++) {
			years.push(yeartIterator++)
		}
		return years.sort(sortYears)
	}

	const fetchSelections = () => {
		getSelections({
			variables: {
				countryQuery: `parent: ${rootCategory} AND linkRewrite.value: selection-${country ? country.isoCode : baseCountry.isoCode}`,
				yearQuery: `name.value: ${currentYear}*`
			}
		})
	}

	const handleYearSelection = (event, year) => {
		event.preventDefault()
		setCurrentYear(year)
	}

	useEffect(() => {
		if (selectionData) {
			const { cms: { categories: { items } } } = selectionData
			setAllSelections((previous) => {
				previous.push({
					year: currentYear,
					...items[0]
				})

				return [...previous]
			})
		}
	}, [selectionData])

	useEffect(() => {
		if (allSelections.length > 0) {
			setCurrent(() => allSelections.find(item => item.year === currentYear))
		}
	}, [allSelections])

	useEffect(() => {
		if (allSelections.find(item => item.year === currentYear)) {
			setCurrent(() => allSelections.find(item => item.year === currentYear))
			return
		}

		fetchSelections()
	}, [currentYear])

	if (loading) {
		return (
			<div className="is-loading"></div>
		)
	}

	const years = generateYears(data)

	return (
		<section
			className={classNames({
				"grey": !isSvod,
				"tvod-color": isSvod
			})}
		>
			<h2 className="section-title icon-selection">{c("selections-previous-months")}</h2>
			<p>{c("selection-message-tvod")}</p>
			<nav className={"switch only-desktop"}>
				{years.map((year, index) => (
					<a
						href="#"
						key={`key-year-${index}`}
						onClick={event => { handleYearSelection(event, year) }}
						rel="nofollow noopener"
						title={year}
						className={`${current.year === year ? "active" : ""}`}
					>
						{year}
					</a>
				))}
				{selectionLoading && (
					<a href="#" key={"key-year-loader"} title={"loader"} className={"loader centered"} />
				)}
			</nav>
			<nav className="only-mobile">
				<span className="select-container switch">
					{years && (
						<select
							defaultValue={current.year}
							onChange={(event) => {
								handleYearSelection(event, event.target.value)
							}}
						>
							{years.map((year, index) => (
								<option key={`key-year-${index}`} value={year}>{year}</option>
							))}
						</select>
					)}
				</span>
			</nav>
			<Selections
				key={`previous-selections-${current.id}`}
				selections={current}
				showSelection={showSelection}
				loading={false}
			/>
		</section>
	)
}

const Slide = ({ selection, selections, showSelection, sortSelections, loading }) => {
	const { c } = useTranslation("common")

	const [date, name] = selection.name.split("_")
	const [year, month] = date.split("-")

	return (
		<a
			href="#"
			onClick={e => {
				e.preventDefault()
				showSelection(
					selection,
					selections.children.items.sort(sortSelections),
					year
				)
			}}
			rel="nofollow noopener"
			title={c("see-selection")}
			className="slide-content"
		>
			<div className={`infos${loading ? " skeleton" : ""}`}>
				<h3>
					<span>{name}</span>
					<span className="date">{moment().month(month - 1).format("MMMM")}</span>
				</h3>
			</div>
			<figure className={loading ? "skeleton" : ""} style={{ aspectRatio: "588/353" }}>
				<picture>
					<Image src={image(selection, CATEGORY_SELECTION_PREVIOUS)} alt={name} />
				</picture>
			</figure>
		</a>
	)
}

const Selections = ({ selections, loading, showSelection }) => {
	const filterSelection = (selection) => {
		const currentMonth = new Date().getMonth() + 1
		const currentYear = new Date().getFullYear()
		const [date, name] = selection.name.split("_")
		const [year, month] = date.split("-")

		return parseInt(month) < currentMonth || parseInt(year) < currentYear
	}

	const sortSelections = (a, b) => ((a.name >= b.name) ? -1 : 1)

	if (selections.children.items.length == 0) {
		return null
	}

	return (
		<Slider
			slides={selections.children.items.sort(sortSelections).filter(filterSelection)}
			slideComponent={Slide}
			slidePropKey="selection"
			slideRelatedProps={{
				selections,
				showSelection,
				sortSelections
			}}
			loading={loading}
			className="min"
			hidePagination
		/>
	)
}

export default Others
