import moment from "moment"

import { useTranslation } from "lib/i18n/translation"
import { useModal } from "lib/modal"

export default ({ access }) => {
	const { c } = useTranslation("common")
	const { hide } = useModal()

	return (
		<>
			<p>
				{c("wait-for-renew-desc")} {moment(access.dateRenewal).format("DD MMMM YYYY")}
			</p>
			<a
				href="#"
				onClick={e => { e.preventDefault(); hide() }}
				rel="nofollow noopener"
				className="button"
			>
				{c("close")}
			</a>
		</>
	)
}
