import { useTranslation } from "lib/i18n/translation"
import moment from "moment"
import { metadata, FEATURE_DATE_TO } from "lacinetek-common/src/metadata"
import image, { CATEGORY_SELECTION } from "lacinetek-common/src/images"
import { useAuth } from "lib/auth"
import { useAccesses } from "lib/accesses"

import Image from "components/common/image"

const Head = ({ selection, loading, tiny, goToOffer }) => {
	const { c } = useTranslation("common")
	const { user } = useAuth()
	const { hasSubscribed } = useAccesses()

	let [year, month] = selection.name.split("_")[0].split("-")
	const selectionDate = moment(`01/${month}/${year}`, 'DD/MM/YYYY')
	const selectionTitle = selection.name.split("_")[1]

	let dateTo = "01/01/2000"
	if (selection) {
		year = parseInt(year)
		month = parseInt(month)
		dateTo = `09/${month == 12 ? 1 : month + 1}/${month == 12 ? year + 1 : year}`
		const metaDate = metadata(selection, FEATURE_DATE_TO)
		if (metaDate) {
			dateTo = metaDate
		}
	}

	return (
		<section className="focus full">
			{
				!tiny
				&& <small className={`alert fixed icon-clock ${loading ? "skeleton" : ""}`}>{c("until")} {moment.parseZone(dateTo, 'DD/MM/YYYY').format('DD/MM')}</small>
			}
			<div className="infos">
				{
					tiny
						? <h2 className={`title-alt selection-title ${loading ? "skeleton" : ""}`}><span>{selectionDate.format("MMMM")} {selectionDate.format("YYYY")}</span><strong>{selectionTitle}</strong></h2>
						: <h2 className={`title-alt large ${loading ? "skeleton" : ""}`}>{c("this-month")} : <strong className={loading ? "skeleton" : ""}>{selectionTitle}</strong></h2>
				}
			</div>
			{
				!tiny
				&& <nav className="focus-nav">
					<a
						href="#selection-films"
						rel="nofollow noopener"
						title={c("see-selection")}
						className={`icon-list ${loading ? "skeleton" : ""}`}
					>
						{c("see-selection")}
					</a>
					{
						!user || !hasSubscribed
						&& <a
							href="#"
							onClick={e => { e.preventDefault(); goToOffer() }}
							rel="nofollow noopener"
							title={c("see-offer")}
							className="icon-selection-alt"
						>
							{c("subscribe")}
						</a>
					}
				</nav>
			}
			<figure className={`background ${loading ? "skeleton" : ""}`}>
				<picture>
					<Image src={image(selection, CATEGORY_SELECTION)} alt={selectionTitle} />
				</picture>
			</figure>
		</section>
	)
}

export default Head
