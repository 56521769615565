import { useTranslation } from "lib/i18n/translation"
import { useModal } from "lib/modal"
import { MONTHLY } from "lib/tools/subscriptions-list"
import { useCountry } from "lib/country"

export default ({ access }) => {

	const { c } = useTranslation("common")
	const { hide } = useModal()
	const { country } = useCountry()

	const currentAccessIsMonthly = country.subscriptions.find(s => s.id == access.product.id).key.includes(MONTHLY)

	return (
		<>
			<p>
				{c(currentAccessIsMonthly ? "already-monthly-desc" : "wait-for-monthly-subscription-desc")}
			</p>
			{!currentAccessIsMonthly && (
				<p>
					{c("wait-for-monthly-subscription-desc-second")}
				</p>
			)}
			<a href="#" onClick={e => { e.preventDefault(); hide() }} rel="nofollow noopener" className="button">{c("close")}</a>
		</>
	)
}
