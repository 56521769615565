import { useState } from "react"
import { useTranslation } from "lib/i18n/translation"
import { metadata, FEATURE_EDITO_CITATION, FEATURE_EDITO_CITATION_AUTHOR } from "lacinetek-common/src/metadata"
import { styledContent } from "lib/tools/contents"
import { monthSelectionDataLayer } from "lib/gtm/interactions"

const ThisMonth = ({selection, tiny, loading}) => {

	const { c } = useTranslation("common")
	const [ more, showMore ] = useState(false)

	const citation = metadata(selection, FEATURE_EDITO_CITATION)
	const citationAuthor = metadata(selection, FEATURE_EDITO_CITATION_AUTHOR)

	const clickMore = (data) => {
		location.hash = !more ? "#selection-desc" : "#edito"
		if (data) {
			monthSelectionDataLayer()
		}
		showMore(data)
	}

	return (<>
		{ (tiny && !citation) ? null : (
			<>
				<section id={tiny ? "selection-overlay-edito" : "edito"}>
					<h2 className={`section-title icon-duration${tiny ? " hidden" : ""}`}>{c("edito")}</h2>
					{!citation ? null : (
						<div className={`quote ${tiny ? "" : "large"} ${loading ? "skeleton" : ""}`}>
							<blockquote>{citation}</blockquote>
							{!citationAuthor ? null : (
								<span className="source">{styledContent(citationAuthor)}</span>
							)}
						</div>
					)}
					{ !tiny
						? <>
								<div id="excerpt" className={`excerpt ${loading ? "skeleton" : ""}`}>
									<p dangerouslySetInnerHTML={{__html: selection.descriptionShort}} />
								</div>
								<div id="edito-full" className={`collapsible ${more ? "active" : ""}`} style={{maxHeight: more ? "150rem" : ""}}>
									<div className={`columnizer ${loading ? "skeleton" : ""}`} data-columns="3" dangerouslySetInnerHTML={{__html: selection.description}} />
								</div>
								<p className={`centered ${loading ? "skeleton" : ""}`}>
									<a href="#" onClick={e => { e.preventDefault(); clickMore(!more) }} title={more ? c("reduce-text") : c("read-more")} className={`collapsible-toggle button small ${!more ? "icon-more": "active icon-less"}`}>{more ? c("reduce-text") : c("read-more")}</a>
								</p>
							</>
						: null
					}
				</section>
			</>
		)}
	</>)
}

export default ThisMonth
