import { Slider } from "components/common"
import Products from "components/common/products"
import SkeletonLink from "components/common/skeleton-link"
import image, { PRODUCT_PHOTOGRAMME } from "lacinetek-common/src/images"
import Image from "components/common/image"
import { useTranslation } from "lib/i18n/translation"

const SubSelection = ({ selection, loading }) => {
	const { c } = useTranslation("common")

	if (loading || !selection.focus) {
		return null
	}

	return (
		<section>
			<h2 className="section-title icon-reel">{c("sub-selection-selection")}</h2>
			<Products products={selection.focus.products.items}>
				{(products, productsLoading) => (
					<Slider
						slides={products}
						slideComponent={Product}
						loading={loading || productsLoading}
						className="grid films"
					/>
				)}
			</Products>
		</section>
	)
}

export default SubSelection

export const Product = ({ loading, index, ...product }) => {
	const { c } = useTranslation("common")

	return (
		<div className="slide-content">
			<SkeletonLink
				href="/film/[id]"
				as={`/film/${product.linkRewrite}`}
				className={loading ? "skeleton" : ""}
			>
				<a title={c("see-single-film")} className="more-link">
					<span className="label">{c("see-single-film")}</span>
				</a>
			</SkeletonLink>
			<div className={`film-link${loading ? " skeleton" : ""}`}>
				<figure>
					<picture>
						<Image
							src={image(product, PRODUCT_PHOTOGRAMME)}
							alt={product.name}
							className={`cover${loading ? " skeleton" : ""}`}
						/>
					</picture>
				</figure>
				<div className={`infos${loading ? " skeleton" : ""}`}>
					<h3 className="title">{product.name}</h3>
					<span className="director">{c("by")} {product.director}</span>
					<span className="year">{product.year}</span>
					<span className="country">{product.origin}</span>
					<span className="duration">{product.duration}</span>
					{!!product.bonusesCount && (
						<ul className="has-bonus">
							<li className="exclusive">{c("bonus")}</li>
						</ul>
					)}
				</div>
			</div>
		</div>
	)
}
