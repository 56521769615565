import { Fragment } from "react"
import image, { PRODUCT_PHOTOGRAMME } from "lacinetek-common/src/images"

import { useTranslation } from "lib/i18n/translation"
import { useMainCategories } from "lib/tools/categories"
import { useVideoPlayer } from "lib/video-player"
import { useAccesses } from "lib/accesses"
import { useBonus } from "lib/bonus"
import { useAuth } from "lib/auth"
import { useModal } from "lib/modal"
import { useCountry } from "lib/country"
import classification from "lib/tools/classification"
import {
	BONUS_ARCHIVES,
	BONUS_EXCLUSIFS
} from "lib/tools/product"

import { Products, Bonuses } from "components/common"
import SkeletonLink from "components/common/skeleton-link"
import Image from "components/common/image"
import PlaylistAdd from "components/modals/playlist-add"

const Films = ({selection, tiny, hideTitle, loading, goToOffer, profile}) => {
	const { allLists } = useMainCategories()
	const { seeFilm, seeExtract } = useVideoPlayer()
	const { showBonuses } = useBonus()
	const { user } = useAuth()
	const { c } = useTranslation("common")
	const { inEuro } = useCountry()
	const { hasSubscribed } = useAccesses()
	const { show } = useModal()

	const filmClicked = (product) => {
		if (profile || (user && hasSubscribed)) {
			seeFilm(product)
		} else {
			goToOffer()
		}
	}

	const handleAddToPlaylist = (event, product) => {
		event.preventDefault()
		if (!user) {
			return
		}
		show({
			id: "playlist",
			children: <PlaylistAdd productId={product.id} />,
			className: "modal short"
		})
	}

	return (
		<section id={tiny ? "selection-overlay-films" : "selection-films"}>
			<h2 className={`section-title icon-list${hideTitle ? " hidden" : ""}`}>{c("the-selection-month-strong")}</h2>
			<ul className={tiny ? "grid min films" : "list films selection"}>
				<Products products={selection.products.items} loading={loading}>
					{ (products, loading) => (
							products.map((product, index) => (
									<li className={`item ${tiny ? "in-selection" : ""} ${inEuro ? "" : "disabled"}`} key={`selection-${selection.id}-product-${index}`}>
										<div className="item-content">
											{ tiny
												? <SkeletonLink href="/film/[id]" as={`/film/${product.linkRewrite}`} key={`film-link-${product.id}`}>
														<a title={c("see-single-film")} className={`more-link ${loading ? "skeleton" : ""}`}><span className="label">{c("see-single-film")} </span></a>
													</SkeletonLink>
												: null
											}
											<div className="film-link">
												{ !tiny
													? <>
															<a
																href="#"
																onClick={e => { e.preventDefault(); filmClicked(product) }}
																rel="nofollow noopener"
																title={c("see-single-film")}
																data-overlay="#overlay-login"
																className={`video-link ${(!profile && (!user || hasSubscribed)) ? "locked" : ""} ${loading ? "skeleton" : ""}`}
															>
																<span className={`label ${loading ? "skeleton" : ""}`}>{c("watch-the-movie")}</span>
																<figure>
																	<picture>
																		<Image src={image(product, PRODUCT_PHOTOGRAMME)} alt={product.name} className={loading ? "skeleton" : ""} />
																	</picture>
																</figure>
															</a>
														</>
													: <>
															<figure>
																<picture>
																	<Image src={image(product, PRODUCT_PHOTOGRAMME)} alt={product.name} className={`${loading ? "skeleton" : ""} cover`} />
																</picture>
															</figure>
															<div className={`infos ${loading ? "skeleton" : ""}`}>
																<h3 className="title">{product.name}</h3>
																<span className="director">{c("by")} {product.director}</span>
																<span className="year">{product.year}</span>
																<span className="country">{product.origin}</span>
																<span className="duration">{product.duration}</span>
															</div>
														</>
												}
											</div>
											{ tiny
												? null
												: <div className={`infos ${loading ? "skeleton" : ""}`}>
														<SkeletonLink href="/film/[id]" as={`/film/${product.linkRewrite}`} key={`film-link-${product.id}`}>
															<a title={c("see-single-film")} className="title"><h3 className="title">{product.name}{classification(product)}</h3></a>
														</SkeletonLink>
														{ product.original_title
															? <span className="original">({product.original_title})</span>
															: null
														}
														<div className={`film-infos ${loading ? "skeleton" : ""}`}>
															<span className="director">{c("by")} {product.director}</span>
															<span className="year">{product.year}</span>
															<span className="country">{product.origin}</span>
															<span className="duration">{product.duration}</span>
														</div>
														{ product.actors && product.actors.length > 0
															? <>
																	<p className={`actors ${loading ? "skeleton" : ""}`}><b>{c("with")}</b> { product.actors.map(a => a.name).join(', ')  }</p>
																	<br />
																</>
															: null
														}
														<div className={`description ${loading ? "skeleton" : ""}`} dangerouslySetInnerHTML={{__html: product.description}} />
														<p className={`is-recommended ${loading ? "skeleton" : ""}`}>
															{ product.recommendersIds && product.recommendersIds.length > 0
																? <>
																		<b>{c("recommended-by")}</b>
																		{ product.recommendersIds.map((id, rIndex) => {
																				const list = allLists.find(l => l.director.id == id)
																				if (!list) return null
																				return (
																					<Fragment key={rIndex}>
																						<SkeletonLink href={`/lists/[id]`} as={`/lists/${c(list.linkRewrite)}`} >
																							<a title={c("see-list")}>{list.name.replace(" | ", " ")}</a>
																						</SkeletonLink>
																						{ rIndex !== (product.recommendersIds.length - 1) ? ", " : ""}
																					</Fragment>
																				)
																		})}
																	</>
																: null
															}
														</p>
														{ (product.bonusesArchivesCount + product.bonusesExclusifsCount) > 0 || product.extractsCount > 0
															? <nav className={`has-bonus ${loading ? "skeleton" : ""}`} aria-label={c("see-related-content")}>
																	{ product.extractsCount > 0
																		? <a href="#" rel="nofollow noopener" title={c("watch-extract")} data-overlay="#overlay-video" className="icon-footage" onClick={e => { e.preventDefault(); seeExtract(product) }}>{c("watch-extract")}</a>
																		: null
																	}
																	{ product.bonusesExclusifsCount > 0
																		? product.bonusesExclusifsCount > 1
																			? <a href="#" onClick={e => { e.preventDefault(); showBonuses(product, BONUS_EXCLUSIFS, c("bonus-exclusifs-title")) }} rel="nofollow noopener" title={c("watch-exclusifs-bonus")} data-overlay="#overlay-bonus-exclusive" className="icon-bonus-exclusive exclusive">{c("bonus-exclusifs-title")}</a>
																			: <Bonuses productId={product.id} type={BONUS_EXCLUSIFS} className="icon-bonus-exclusive exclusive" />
																		: null
																	}
																	{ product.bonusesArchivesCount > 0
																		? product.bonusesArchivesCount > 1
																			? <a href="#" onClick={e => { e.preventDefault(); showBonuses(product, BONUS_ARCHIVES, c("bonus-archives-title")) }} rel="nofollow noopener" title={c("watch-archives-bonus")} data-overlay="#overlay-bonus-archives" className="icon-bonus-archives archives">{c("bonus-archives-title")}</a>
																			: <Bonuses productId={product.id} type={BONUS_ARCHIVES} className="icon-bonus-archives archives" />
																		: null
																	}
																</nav>
															: null
														}
												</div>
											}
										</div>
										{ tiny
											? null
											: <nav className={`film-actions ${loading ? "skeleton" : ""}`} aria-label={c("film-actions")}>
													{ loading
														? null
														: <>
																<SkeletonLink href="/film/[id]" as={`/film/${product.linkRewrite}`} key={`product-link-${product.id}`}>
																	<a title={c("see-single-film")} className=" icon-file">{c("see-single-film")}</a>
																</SkeletonLink>
																<a href="#" onClick={event => handleAddToPlaylist(event, product)} rel="nofollow noopener" title={c("add-to-my-playlist")} data-overlay="#overlay-playlist" className={`icon-playlist-add ${ user ? "" : "disabled" }`}>{c("add-to-my-playlist")}</a>
															</>
													}
												</nav>
										}
									</li>
							))
					)}
				</Products>
			</ul>
		</section>
	)
}

export default Films
