import { useTranslation } from "lib/i18n/translation"
import SkeletonLink from "components/common/skeleton-link"
import Image from "components/common/image"

export default ({}) => {
	
	const { c } = useTranslation("common")

	const message = "page-not-found"
	const statusCode = 404

	return (
		<section className="centered">
			<div className="oups">
				<div className="infos">
					<h1>{statusCode ? <strong>#{statusCode}</strong> : null}<br />{c(message)}</h1>
				</div>
				<figure>
					<picture>
						<Image src="/static/images/tv.png" alt="404" style={{ background: "url(/static/images/noise.gif)" }} />
					</picture>
				</figure>
			</div>
			<p className="introduction">{c("sorry-for-the-interruption")}</p>
			<p className="section-link">
				<SkeletonLink href="/">
					<a title={c("back-homepage")} className="icon-left button">{c("back-homepage")}</a>
				</SkeletonLink>
			</p>
		</section>
	)
}
