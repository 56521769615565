import { useState } from "react"
import classNames from "classnames"

import { useCart } from "lib/cart"
import { useModal } from "lib/modal"
import { useTranslation } from "lib/i18n/translation"
import { useAccesses } from "lib/accesses"
import { useCountry } from "lib/country"
import { sanitizeUrl } from "lib/redirects"
import { MONTHLY } from "lib/tools/subscriptions-list"

import WaitForRenew from "components/modals/wait-for-renew"
import AlreadyMonthly from "components/modals/already-monthly"

export const SubscribeButton = ({ type, subscriptionId, text, full = false }) => {
	const { c } = useTranslation("common")
	const { subscriptions, addProductToCart } = useCart()
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(false)
	const { show } = useModal()
	const { filterCountry: country, purchasable, originalCountry, showAnotherCountry } = useCountry()
	const { subscription: userSubscription } = useAccesses()
	const isOnlyLink = subscriptionId && isNaN(parseInt(subscriptionId))

	let subscription = null

	if (!isOnlyLink) {
		if (subscriptionId) {
			subscription = subscriptions.find(sub => (
				country?.subscriptions?.find(subCountry => subCountry.subscriptionId == subscriptionId)?.id === parseInt(sub.id)
			))
		} else {
			subscription = subscriptions.find(sub => (
				country?.subscriptions?.find(subCountry => subCountry.key == type)?.id === parseInt(sub.id)
			))
		}
	}

	const addSubscriptionToCart = (event) => {
		if (isOnlyLink) {
			return
		}
		event.preventDefault()
		if (purchasable) {
			if (!originalCountry) {
				showAnotherCountry()
			} else if (!loading) {
				if (!subscription) {
					console.log("This subscription is not recorded")
					setError(true)
					setTimeout(() => { setError(false) }, 5000)
					return null
				}
				if (userSubscription) {
					if (type === MONTHLY || userSubscription.isMonthly) {
						show({
							id: "already-monthly",
							title: c("already-monthly-subscription"),
							children: <AlreadyMonthly subscription={subscription} access={userSubscription} />,
							className: "modal"
						})
					} else {
						show({
							id: "wait-for-renew",
							title: c("wait-for-renew"),
							children: <WaitForRenew subscription={subscription} access={userSubscription} />,
							className: "modal"
						})
					}
				} else {
					setLoading(true)
					return addProductToCart(subscription)
						.then(data => {
							setLoading(false)
							return data
						})
				}
			}
		}
	}

	return (
		<>
			<a
				href={isOnlyLink ? sanitizeUrl(subscriptionId) : "#"}
				onClick={event => addSubscriptionToCart(event)}
				rel="nofollow noopener"
				title={text ? text : c("subscribe")}
				className={classNames(
					"button",
					{ "small": !full },
					{ "full": full },
					{ "is-loading": loading },
					{ "disabled": !purchasable }
				)}
			>
				{text ? text : c("subscribe")}
			</a>
			{
				error
				&& <span className="form-error">{c("error-thrown")}</span>
			}
		</>
	)
}
