import { useState } from "react"
import { useApolloClient } from "@apollo/react-hooks"

import { metadata, FEATURE_GUEST_CITATION, FEATURE_GUEST_CITATION_AUTHOR } from "lacinetek-common/src/metadata"

import { useTranslation } from "lib/i18n/translation"
import image, { CATEGORY_BONUS } from "lacinetek-common/src/images"
import { styledContent } from "lib/tools/contents"
import { FETCH_CATEGORY_VIDEO_PLAYER_BY_VIDEO_ID, FETCH_KINOW_CATEGORY_VIDEO_ID } from "lib/graphql/queries"
import { Query } from "components/common"

const Guest = ({selection, loading, tiny, hideVideoPlayerContainer}) => {

	const { c } = useTranslation("common")

	if (loading) {
		return null
	}

	const authorSentence = metadata(selection, FEATURE_GUEST_CITATION_AUTHOR)

	let Container = "div"
	if (tiny) {
		Container = "section"
	}

	return <Query
		query={FETCH_KINOW_CATEGORY_VIDEO_ID}
		options={{ variables: { id: selection.id } }}
		renderLoading={() => (<></>)}
	>
		{({ cms: { categories: { items: [{ videos: { items: [video] } }] } } }) => {
			if (!video) {
				return null
			}

			return (
				<Container id="selection-guest">
					<h2 className="section-title icon-guest">{c("guest-of-the-month-strong")}</h2>
					<div className={`${tiny || "splitted"} selection-guest`}>
						{ hideVideoPlayerContainer
							? <VideoPlayer selection={selection} videoId={video.id} />
							: <div className={`videoplayer-container ${loading ? "skeleton" : ""}`}>
									<VideoPlayer selection={selection} videoId={video.id} />
								</div>
						}
						{ authorSentence && authorSentence != ""
							? <div className="infos">
									{ !tiny && metadata(selection, FEATURE_GUEST_CITATION) && metadata(selection, FEATURE_GUEST_CITATION) !== ""
										? <div className={`quote ${loading ? "skeleton" : ""}`}>
												<blockquote>{styledContent(metadata(selection, FEATURE_GUEST_CITATION))}</blockquote>
											</div>
										: null
									}
									<span className={`source ${loading ? "skeleton" : ""}`} dangerouslySetInnerHTML={{ __html: authorSentence }} />
								</div>
							: null
						}
					</div>
				</Container>
			)
		}}
	</Query>
}

export const VideoPlayer = ({
	selection,
	videoId,
	thumb = CATEGORY_BONUS
}) => {

	const [loadingVideo, setLoadingVideo] = useState(false)
	const [videoUrl, setVideoUrl] = useState(null)
	const client = useApolloClient()
	const showVideo = () => {
		setLoadingVideo(true)
		client.query({
			query: FETCH_CATEGORY_VIDEO_PLAYER_BY_VIDEO_ID,
			variables: { id: selection.id, videoId },
			fetchPolicy: "network-only"
		})
			.then(({data: { cms: { categories: { items: [ { videos: { items: videos } } ] } } } }) => {
				if (videos.length && videos[0].player) {
					setVideoUrl(videos[0].player.url)
				} else {
					setVideoUrl(null)
				}
				setLoadingVideo(false)
			})
	}

	if (videoUrl) {
		return (
			<div className="youtube videoplayer">
				<iframe src={videoUrl}  allow="autoplay ; fullscreen ; encrypted-media" allowFullScreen />
			</div>
		)
	}

	return (
		<div data-video="byJOxaEVyng" className="youtube videoplayer" style={{backgroundImage: `url(${image(selection, thumb)})`}}>
			<div className={loadingVideo ? "is-loading" : "play"} style={loadingVideo ? { position: "absolute", top: "50%", left: "50%"} : {}} onClick={showVideo}></div>
		</div>
	)
}

export default Guest
