import { useTranslation } from "lib/i18n/translation"
import { useCountry } from "lib/country"
import { SELECTION } from "lib/tools/categories-list"
import { FETCH_SELECTION } from "lib/graphql/queries"

import { baseSelection } from "components/common/skeleton"
import { Query } from "components/common"
import {
	Head,
	ThisMonth,
	Guest,
	Teaser,
	Films
} from 'components/selection'

import NotFound from "./404"

export const SelectionModal = ({ slug }) => {
	const { country } = useCountry()
	const rootCategory = process.env.CATEGORIES.find(c => c.key == SELECTION).id

	return (
		<Query
			query={FETCH_SELECTION}
			options={{ variables: { slug, countryQuery: `parent: ${rootCategory} AND name.value:*-${country.isoCode}` } }}
			renderLoading={() => (
				<SelectionModalComponent selection={baseSelection} loading={true} />
			)}
		>
			{({ cms: { categories: { items: [{ children: { items: [selection] } }] } } }) => {
				return <SelectionModalComponent selection={selection} />
			}}
		</Query>
	)
}

const SelectionModalComponent = ({ selection, loading }) => (
	<>
		<Head selection={selection} loading={loading} tiny={true} />
		<ThisMonth selection={selection} loading={loading} tiny={true} />
		<Films
			key={`selection-modal-films-for-${selection.id}`}
			selection={selection}
			loading={loading}
			tiny={true}
		/>
		<Guest
			selection={selection}
			loading={loading}
			tiny={true}
			hideVideoPlayerContainer={true}
		/>
		<Teaser selection={selection} loading={loading} tiny={true} />
	</>
)

export const SelectionModalFooter = ({ previousSelection, nextSelection, show, year }) => {
	const { c } = useTranslation("common")

	return (
		<nav className="buttons">
			<a
				href="#"
				onClick={e => { e.preventDefault(); show(previousSelection) }}
				rel="nofollow noopener"
				title={c("see-previous-selection")}
				className={`previous ${previousSelection ? "" : "disabled"}`}
			>
				{c("see-previous")}
			</a>
			<span style={{ textAlign: "center" }}>{year}</span>
			<a
				href="#"
				onClick={e => { e.preventDefault(); show(nextSelection) }}
				rel="nofollow noopener"
				title={c("see-next-selection")}
				className={`next ${nextSelection ? "" : "disabled"}`}
			>
				{c("see-next")}
			</a>
		</nav>
	)
}

export default () => <NotFound />
